import React from "react"
import styled from "styled-components"

const Grid = styled.div`
  padding: 0 10px;
  margin: 0 auto;
  display: grid;
  grid-auto-rows: 250px;
  grid-gap: 10px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(2, 200px);

  @media screen and (min-width: 660px) {
    grid-template-columns: repeat(3, 200px);
  }
  @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(4, 200px);
  }
  @media screen and (min-width: 1300px) {
    grid-template-columns: repeat(5, 200px);
    margin-left: 0;
  }
`

export default ({ children }) => <Grid>{children}</Grid>
