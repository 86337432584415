import React from "react"
import styled from "styled-components"
import Grid from "./grid"
import { Link } from "gatsby"
import SEO from "../components/seo"

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  @media (min-width: 800px) {
    grid-template-columns: 1fr 2fr;
  }
`
const Menu = styled.nav`
  padding: 0 10px;
  margin-left: 0;
  @media (min-width: 800px) {
    margin-left: auto;
  }
`
const MenuItem = styled.h3``

export default ({ children }) => (
  <Container>
    <SEO title="Gallery" />
    <Menu>
      <MenuItem>
        <Link to={"/gallery/sculptures"}>Sculptures</Link>
      </MenuItem>
      <MenuItem>
        <Link to={"/gallery/relief"}>Relief</Link>
      </MenuItem>
      <MenuItem>
        <Link to={"/gallery/doors"}>Doors</Link>
      </MenuItem>
    </Menu>
    <Grid>{children}</Grid>
  </Container>
)
