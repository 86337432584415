import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { rhythm } from "../utils/typography"

const getRatio = ({ width, height }) => height / width

const getShape = ratio => {
  if (ratio < 0.85) return "wide"
  if (1.6 < ratio) return "tall"
  return "small"
}
const sizes = {
  wide: `
    grid-column: span 2;
    grid-row: span 1;
  `,
  tall: `
    grid-column: span 1;
    grid-row: span 2;
  `,
  small: `
    grid-column: span 1;
    grid-row: span 1;
  `,
  large: `
    grid-column: span 2;
    grid-row: span 2;
  `,
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  ${({ shape }) => sizes[shape]};
`
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`
const Text = styled.p`
  margin: ${rhythm(1)};
  margin-top: ${rhythm(0.5)};
`
const Title = styled.h2`
  margin: ${rhythm(1)};
  margin-bottom: 0;
`

export const ImageBox = ({ node, shape }) => {
  if (!shape) {
    if (node.childImageSharp.original) {
      shape = getShape(getRatio(node.childImageSharp.original))
    } else {
      shape = "small"
    }
  }
  return (
    <Box shape={shape}>
      <StyledImage fluid={node.childImageSharp.fluid} />
    </Box>
  )
}

export const TextBox = ({ children, shape, title }) => {
  if (!shape) {
    shape = "wide"
  }

  return (
    <Box shape={shape}>
      <Title>{title}</Title>
      <Text>{children}</Text>
    </Box>
  )
}
